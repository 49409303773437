import { graphql, useStaticQuery } from "gatsby"

import BlogList from "../containers/BlogList"
import ChangeYourLife from "../containers/ChangeYourLife"
import { ContentWrapper } from "../containers/elegant.style"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import React from "react"
import SEO from "../components/SEO"

const BlogListPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query BlogList {
      allContentfulBlogPost(
        sort: { fields: updatedAt, order: DESC }
      ) {
        edges {
          node {
            blogPostCategory
            blogPostSlug
            blogPostTitle
            blogPostMainImage {
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            blogPostIntroText {
              blogPostIntroText
            }
            createdAt(formatString: "MMMM DD, y")
          }
        }
      }
    }
  `)
  const { allContentfulBlogPost } = data

  const url = location.href ? location.href : ""
  return (
    <>
      <SEO
        url={url}
        title="Elegant Beauty Clinic Blog"
        shortDesc="Elegant Beauty Clinic"
        description="Browse our range of skin, health and weigh loss related articles, advice and tips on keeping your skin looking young, healthy and full of glow."
      />
      <Layout>
        <ContentWrapper>
          <PageTitle title="The Elegant Blog" subtitle="News / Tips / Advice" />
          <BlogList blogListContent={allContentfulBlogPost} />
          <ChangeYourLife />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default BlogListPage
